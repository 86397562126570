import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useTextContext } from '../../../../context/contexts/text/TextContext';
import Theme from '../../../../style/theme';
import BasicButton from '../../../Buttons/BasicButton';

const Form = () => {
	const {data, getFileURL, setIntroductionState} = useDataContext();
	const {t} = useTextContext();
	const [codeInput, setCodeInput] = useState<string>('');
	const [checkingState, setCheckingState] = useState<number>(0);
	
	const handleKeyPress = (e) => {
		switch (e.keyCode) {
		case 13:
			CheckPassword();
			break;
		}                                       
	};

	const CheckPassword = () => {
		setCheckingState(1);
		setTimeout(() => {
			if(codeInput === data?.password?.code){	
				setIntroductionState && setIntroductionState(1);
				setTimeout(() => {
					setIntroductionState && setIntroductionState(2);
				}, 1500);
			} else {
				setCheckingState(2);
			}
		}, 1500);			
	};

	return (
		<Container>
			<div>
				{checkingState == 1 ? 
					<Loader>	
						<Load img={getFileURL && getFileURL('9346c49e-16e5-41e2-a54c-d5ee6dcb24b9')}>
						</Load>
					</Loader>
					: <Input onKeyDown={handleKeyPress} value={codeInput} onChange={(e) => setCodeInput(e.target.value)}placeholder={t('login.input.placeholder')} type="text"></Input>}
				<IncorrectMessage visible={checkingState === 2 ? true : false}>
					<div>
						<Sign url={getFileURL && getFileURL('b41d522c-6d95-4c5f-a047-5d49766c7d0b')}></Sign>
					</div>
					<div style={{marginLeft: '10%'}}>
						<Title style={{fontWeight: 'normal'}}>{t('incorrectmessage.title')}</Title>
						<p style={{fontSize: '0.8rem'}}>{t('incorrectmessage.text')}</p>
					</div>				
				</IncorrectMessage>
			</div>
			<BasicButton style={{height: '50px', marginInline: 0}} onClick={CheckPassword}>										
				{t('buttons.start')}
			</BasicButton>
		</Container>);
};

//styled components
const Shake = keyframes`
	0% { transform: translateX(0) }
	25% { transform: translateX(10px) }
	50% { transform: translateX(-10px) }
	75% { transform: translateX(10px) }
	100% { transform: translateX(0) }
`;

const rotate = keyframes`
	0% { -webkit-transform: rotate(0deg)}
	100% { -webkit-transform: rotate(-360deg)}
`;

const Load = styled.div<{img: string | undefined}>`
	position: relative;
	width: 50px;
	height: 50px;
	border-radius: 50px;

	z-index: 2;

	background-image: url(${p => p.img});
	background-size: cover;
	background-position: center;

	animation: ${rotate} 1s ease-in-out;
`;

const Loader = styled.div`
	width: 350px;
	height: 50px;
	margin-right: 20px;

	display: flex;
	justify-content: center;

`;

const Container = styled.div`
	margin-top: auto; 
	display: flex;
	justify-content: center;

	transform: translateY(50%);
`;

const Input = styled.input`
	box-shadow: inset 0 0 0 2px ${Theme.colors.secondary};
	border: 0;
	width: 350px;
	height: 50px;
	margin-right: 20px;
	padding: 10px;
	font-size: 1.5rem;
	border: none;
	outline: none;
	color: ${Theme.colors.neutralDark};
`;

const IncorrectMessage = styled.div<{visible: boolean}>`
	margin-top: 10px;
	width: 350px;
	height: 90px;
	margin-right: 20px;
	padding: 10px;

	background-color: rgba(0, 75, 65, 0.7);

	display: flex;
	align-items: center;

	visibility: 'hidden';

	visibility: ${p => p.visible ? 'visible' : 'hidden'};
	${ p => p.visible && css`
		visibility: 'visible';
		animation: ${Shake} 0.2s ease-in-out 2;
	`} 
`;

const Title = styled.h1`
	color: ${Theme.colors.neutral};
	font-size: 1.5rem;
	margin-bottom: 5px;
`;

const Sign = styled.div<{url: string | undefined}>`
    background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	height: 50px;
	width: 50px;
	margin: auto;
`;


export default Form;