import { PopupContentProps } from '../PopupContent';
import Container from '../../../Containers/Container';
import styled from 'styled-components';
import Theme from '../../../../style/theme';
import Button from '../../../Buttons/Button';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useEffect, useRef } from 'react';
import { useAudioContext } from '../../../../context/contexts/audio/AudioContext';
import reactMarkdown from 'react-markdown';
import BasicButton from '../../../Buttons/BasicButton';
import { useTextContext } from '../../../../context/contexts/text/TextContext';

const Video = ({popupData, onClose} : PopupContentProps) => {

	const {getFileURL} = useDataContext();
	const {videoMuteState, setVideoActive} = useAudioContext();
	const {t} = useTextContext();
	const vid = useRef<HTMLIFrameElement>(null);

	const close = () => {
		onClose('video closed');
		setVideoActive && setVideoActive(false);
	};

	useEffect(() => {			
		if(vid.current && vid.current.src)
			if(videoMuteState){
				vid.current.src = popupData.video_url+'&muted=1';
			} else {
				vid.current.src = popupData.video_url+'&muted=0';	
			}
	},[videoMuteState]);


	return(			
		<Container fadeIn={true} focusBlock={true}>
			{/* <ContentContainer> */}
			<TopContent>
				<div style={{display: 'flex', alignItems: 'center', marginTop: 'none'}}>
					<h2 >{popupData.title} / </h2>
					<h1 style={{marginInline: '15px'}} className='english'>{popupData.title_english}</h1>
				</div>
				<p style={{fontWeight: 'bold'}}><StyledReactMarkdown linkTarget={'_blank'}>{popupData.description_markdown}</StyledReactMarkdown></p>
				<p className={'english'} style={{fontStyle: 'italic'}}><StyledReactMarkdown linkTarget={'_blank'}>{popupData.description_markdown_english}</StyledReactMarkdown></p>
			</TopContent>
			<Vimeo ref={vid} src={popupData.video_url+`&muted=${videoMuteState ? '1' : '0'}`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
			<CloseRow>
				<CloseButton onClick={() => close()}>{t('video.continue')}</CloseButton>
			</CloseRow>
		</Container>
	);
};

// styled components

const CloseRow = styled.div`
	display:flex;
`;
const CloseButton = styled(BasicButton)`
	margin-left: auto!important;

`;

const ContentContainer = styled.div`
	overflow-y: auto;
`;

const StyledReactMarkdown = styled(reactMarkdown)`

	& a, a:visited {
		color: ${Theme.colors.secondary};
	}

`;

const TopContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-weight: bold;
`;

const Vimeo = styled.iframe`
/* width: 100%; */
	flex-grow: 1;
	/* background-color: transparent; */

	& > html > body {
		display: block!important;
	}
	/* aspect-ratio: 1/0.001; */
`;

const Close = styled(Button)<{urls: {normal: string | undefined, hover: string | undefined}}>`
	position: absolute;
	right:-${Theme.responsive.whitespace.sm}px;
	top:-${Theme.responsive.whitespace.sm - 50}px;

	margin: 30px;

	height: 50px;
	width: 50px;

    background-image: url(${p => p.urls.normal});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	&:hover{
		background-image: url(${p => p.urls.hover});
	}
`;

export default Video;