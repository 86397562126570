import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { useTextContext } from '../../../../context/contexts/text/TextContext';
import { FadeOut } from '../animations';
import Form from './Form';

const Login = () => {
	const {getFileURL, introductionState} = useDataContext();
	const {t} = useTextContext();

	return (
		<Container fade={introductionState === 1 ? true : false}>
			<Headings>
				<HeadingWrapper>
					<h1>
						{t('login.title.1.upper')}<br/>
						{t('login.title.1.lower')}
					</h1>
				</HeadingWrapper>
				<Logo url={getFileURL && getFileURL('48ea874e-fa2d-4eca-a86f-2bb1ee0a2f3a')}/>
				<HeadingWrapper>
					<h1>						
						{t('login.title.2.upper')}<br/>
						{t('login.title.2.lower')}
					</h1>
				</HeadingWrapper>
			</Headings>				
			<Form/>
		</Container> 
	);
};

//styled components

const Container = styled.div<{fade: boolean | undefined}>`
	display: flex;
	flex-direction: column;
	height: 100%;
	${ p => p.fade && css`
		animation: ${FadeOut} 1s forwards;
	`} 

`;

const logoScale = 1.3;

// Headings 

const Headings = styled.div`
	display: flex;
	justify-content: center;
`;

const HeadingWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: center;
	/* align-items: center; */

	& > h1 {
		/* color: red; */
		/* word-spacing:9999px; */
		text-align:  left;
		width: fit-content;
	}
`;

const Logo = styled.div<{url: string | undefined}>`

	width: ${110 * logoScale}px;
	height: ${150 * logoScale}px;

	background-image: url(${p => p.url});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	flex-shrink: 0;

`;

export default Login;