import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import Theme from '../../../style/theme';
import BasicButton from '../../Buttons/BasicButton';
import Container from '../../Containers/Container';
import { FadeIn } from '../Introduction/animations';

const Endscreen = () => {

	const {showEndscreen} = useGlobalContext();
	const {getFileURL} = useDataContext();
	const {t} = useTextContext();

	const buttonClick = () => {
		window.open(t('endscreen.leeromgeving.link'), '_blank');
	};

	return (<>
		{showEndscreen && <OuterContainer background={getFileURL && getFileURL('03a2abc5-c9c5-4746-8870-a04fb53038f0')}>
			<Container focusBlock={true}>
				<InformationWrapper>
					<h1 style={{maxWidth:'50%'}}>{t('endscreen.title.standard')}</h1>
					<BasicButton style={{marginLeft: '0', marginTop: '7px'}} onClick={buttonClick}>
						{t('endscreen.buttontext')}
					</BasicButton>
					<p style={{maxWidth:'45%', marginBlock: '15px'}}>
						{t('endscreen.text.standard')}
					</p>
					<h1 className={'english'} style={{maxWidth:'40%', paddingTop: '30px'}}>{t('endscreen.title.english')}</h1>
					<p className={'english'} style={{maxWidth:'40%', marginBlock: '15px'}}>
						{t('endscreen.text.english')}
					</p>
				</InformationWrapper>
			</Container>
		</OuterContainer>}</>
	);
};

// styled components
const InformationWrapper = styled.div`

	display: flex;
	flex-direction: column;

	height: 100%;

	opacity: 0;
	animation-delay: 1s;

	animation: ${FadeIn} 1s forwards;

	overflow: auto;

`;

const OuterContainer = styled.div<{background: string | undefined}>`
	position: absolute;
	left:0;top:0;

	overflow: hidden;

    height:100%;
    width:100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${p => p.background});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	background-color: ${Theme.colors.primaryDark};

	opacity: 0;
	animation-delay: 1s;
	animation: ${FadeIn} 1.5s forwards;

	z-index: 9;
`;

export default Endscreen;