import { ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Theme from '../../style/theme';
import FocusBlock from './components/FocusBlock';

// This is our universal container for most content
const Container = (props: ContainerProps) => {
	return (
		<_Container introduction={props.usedForIntroduction} fadeIn={props.fadeIn ? true : false}>
			<div style={{height:'100%', width: '100%', position: 'relative'}}>
				{props.children}
				{ props.focusBlock && 
				<FocusBlock/>
				}
			</div>
		</_Container>
	);
};

const fadeIn = keyframes`
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const _Container = styled.div<{fadeIn: boolean, introduction: boolean | undefined}>`

	position: relative;
	height: 100%;

	@media (max-width: ${Theme.responsive.media.lg}){
        padding-block: ${Theme.responsive.whitespace.sm - 50}px;
        margin-inline: ${Theme.responsive.whitespace.sm}px;
	}

	@media (min-width: ${Theme.responsive.media.lg}){
        padding-block: ${Theme.responsive.whitespace.lg - 50}px;
        margin-inline: ${Theme.responsive.whitespace.lg}px;
	}

	${p => p.fadeIn && css`
		animation: ${fadeIn} 1s forwards;
	`}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}
	${p => p.introduction && css`	
		width: 100%;
		display: flex;
		flex-direction: column;
	`}


`;



// types

type ContainerProps = {
	focusBlock?: boolean;
    fadeIn?: boolean;
	usedForIntroduction?: boolean;
	children: ReactNode;
}

export default Container;
