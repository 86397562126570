import { ReactNode } from 'react';
import { AudioContextProvider } from './contexts/audio/AudioContext';
import { DataContextProvider } from './contexts/data/DataContext';
import { GlobalContextProvider } from './contexts/global/GlobalContext';
import { TextContextProvider } from './contexts/text/TextContext';

const ContextProvider = (props:{children: ReactNode}) => {
	return (	
		<TextContextProvider>				
			<AudioContextProvider>
				<DataContextProvider>
					<GlobalContextProvider>				
						{props.children}								
					</GlobalContextProvider>					
				</DataContextProvider>				
			</AudioContextProvider>
		</TextContextProvider>
	);
};

export default ContextProvider;