import eventsRequest from './events';
import filesRequest from './files';
import passwordRequest from './password';
import popupsRequest from './popups';
import videoRequest from './video';
import videoEventsRequest from './videoEvents';

// an array filled with the different requests made to the CMS. 

const requests = [
	filesRequest,
	videoRequest,
	eventsRequest,
	popupsRequest,
	passwordRequest,
	videoEventsRequest,
];

export default requests;