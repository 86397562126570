import styled from 'styled-components';
import Theme from '../../../../style/theme';
import { PopupContentProps } from '../PopupContent';
import Container from '../../../Containers/Container';
import { useEffect, useState } from 'react';
import NotchedDiv from '../../../Containers/NotchedDiv';

const optionLabelsArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];

const MultipleChoice = ({popupData, onClose} : PopupContentProps) => {

	// process data from popupdata into different options

	useEffect(() => {

		if(!popupData) return;

		setOptions(popupData.options.split(','));
		setOptionsEnglish(popupData.options_english.split(','));
	}, []);


	const [optionsEnglish, setOptionsEnglish] = useState<string[]| undefined>();
	const [options, setOptions] = useState<string[]| undefined>();

	return(
		<Container focusBlock={true} fadeIn={true}>
			<ContentContainer>
				<div>
					<h1 style={{textTransform:'uppercase', marginBottom: '10px'}}>{popupData.title}</h1>
					<h1 className='english' style={{textTransform:'uppercase', marginBottom: '10px'}}>{popupData.title_english}</h1>
				</div>
				<Options>
					<OptionsContainer>
						{optionsEnglish && options && options.map((value, index) => 
							<Option 
								key={`pc-mc-option-${index}`}
								onClick={() => onClose(optionLabelsArray[index])}>
								<OptionLabel>
									{optionLabelsArray[index]}
								</OptionLabel>
								<OptionText>
									<Backdrop/>
									<Text>
										<p>{value}</p>
										<i><p style={{fontSize: '0.95rem'}}>&nbsp;/&nbsp;{optionsEnglish[index]}</p></i>
									</Text>
								</OptionText>
							</Option>
						)}
					</OptionsContainer>
				</Options>
			</ContentContainer>

		</Container>
	);
};

// styled components

const ContentContainer = styled.div`
	overflow-y: auto;
	margin-inline: -50px;
	padding-inline: 50px;
`;

const Options = styled.div`
	flex-grow: 1;
	font-weight: bold;
`;

const OptionsContainer = styled.div`
	gap: 20px;
	display: flex;
	flex-direction: column;
`;

const Option =  styled(NotchedDiv)`
	position: relative;

	display: flex;

	height: 60px;
	cursor: pointer;

	background-color: ${Theme.colors.secondary};
	color: ${Theme.colors.primaryDark};

	overflow: hidden;

	transition: transform .2s ease;
	
	
	&:hover{
		transform: translateX(10px);
	}	
`;

const OptionLabel = styled.div`
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 1.3rem;
`;

const OptionText = styled.div`
	position: relative;
	flex-grow: 1;

	display: flex;
	justify-content: left;
	align-items: center;

	background-color: white;
`;

const borderSize = 3;
const Text = styled(NotchedDiv)`
	position: absolute;
	left:0; top: ${borderSize}px; right: ${borderSize}px; bottom: ${borderSize}px;

	/* height: 100%; */
	/* width: 100%; */

	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 15px;

	background: white;
`;

const Backdrop = styled(NotchedDiv)`
	position: absolute;
	inset:0;
	background-color: ${Theme.colors.secondary};
`;

export default MultipleChoice;
