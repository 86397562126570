import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import eventListener, { forceScrollCall } from '../Input/events/scroll';
import LoaderClock from './LoaderClock';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { initializeEvents, updateEventChecking } from './videoEvents';
import Theme from '../../style/theme';
import ScrollTooltip from '../ScrollTooltip/ScrollTooltip';
import ScrollTutorial from './ScrollTutorial';

const VideoBackground = () => {

	// contexts
	const {data, getFileURL, introductionState, setIntroductionState} = useDataContext();
	const {setCurrentPlayTime, newPlayTime, setVideoStopped, videoStopped, setShowEndscreen, showEndscreen, showExtraTooltip, setShowExtra} = useGlobalContext();

	// refs
	const videoPlayer = useRef<{forwards: HTMLVideoElement | null, backwards: HTMLVideoElement | null}>({forwards: null, backwards: null});

	// usestates
	const [activePlayer, setActivePlayer] = useState<VideoPlayer>('forwards');
	const [queuedPlayer, setQueuedPlayer] = useState<VideoPlayer | null>();
	const [stopped, setStopped] = useState<boolean>(false);

	// first render
	useEffect(() => {
		// first render
	},[]);

	useEffect(() => {

		if(introductionState !== 5) return;
		
		eventListener();
		document.addEventListener('scrollacceleration', onValueChange);
		document.addEventListener('videoEvent', videoEventHandler as EventListener);
		data && data.video && initializeEvents(data.video_events, data.video.video_events, data.video_video_events);

		return () => {
			document.removeEventListener('scrollacceleration', onValueChange);
			document.removeEventListener('videoEvent', videoEventHandler as EventListener);
		};

	},[introductionState]);

	useEffect(() => {
		document.removeEventListener('scrollacceleration', onValueChange);
		document.removeEventListener('videoEvent', videoEventHandler as EventListener);
	},[showEndscreen]);
	
	//#region video players functionality

	// value passed by the 'scrollacceleration' event and then trimmed to 2 decimals
	const [value, setValue] = useState<number>(0);
	const [direction, setDirection] = useState<VideoPlayer>('forwards');

	// listener fucntion for the 'scrollacceleration'event
	const onValueChange = (e) => {	
		// if(stopped) return; // ignore events if stopped is true
		setDirection(e.detail.forwards ? 'forwards' : 'backwards');	
		setValue(e.detail.acceleration.toFixed(2));
	};

	const updateVideoPlayer = () => {

		const currentPlayer  = videoPlayer.current[activePlayer];

		if(!currentPlayer) return;

		if(direction !== activePlayer){
			currentPlayer.playbackRate = 0;
			return;
		}

		if(currentPlayer.currentTime === currentPlayer.duration){
			currentPlayer.playbackRate = 0;
			return;
		}

		// if the currently active player is paused, start playing.
		if(currentPlayer.paused){
			currentPlayer.play();
		}

		// if the given threshold is below the threshhold, set playbackRate to 0
		if(value < 0.1){
			currentPlayer.playbackRate = 0;
		}
		else{
			currentPlayer.playbackRate = value;
		}

	};

	const updateInactivePlayer = () => {

		if(!queuedPlayer) return;
		
		const inactivePlayer = videoPlayer.current[queuedPlayer];

		if(!videoPlayer.current || !videoPlayer.current.backwards || !videoPlayer.current.forwards || !activePlayer || !inactivePlayer || isNaN(inactivePlayer.duration)) return;

		if(queuedPlayer === 'forwards'){
			videoPlayer.current.forwards.currentTime = videoPlayer.current.forwards.duration - videoPlayer.current.backwards.currentTime;
			videoPlayer.current.backwards.playbackRate = 0;
		}
		else{
			videoPlayer.current.backwards.currentTime = videoPlayer.current.backwards.duration - videoPlayer.current.forwards.currentTime;
			videoPlayer.current.backwards.playbackRate = 1;
		}
	};

	const onReadyToSwap = () => {
		if(!queuedPlayer) return;
		forceScrollCall(queuedPlayer === 'forwards' ? true : false);
		setActivePlayer(queuedPlayer);
		setQueuedPlayer(null);
	};

	useEffect(() => {
		if(!queuedPlayer) return;

		updateInactivePlayer();
	}, [queuedPlayer]);

	useEffect(() => {
		console.log('value:', value);
		if(stopped) return;	

		updateVideoPlayer();
		videoPlayer.current.forwards?.currentTime && setCurrentPlayTime && setCurrentPlayTime(videoPlayer.current.forwards.currentTime);
	},[value]);

	useEffect(() => {
		if(!direction || stopped) return;

		if(direction != activePlayer){
			setQueuedPlayer(direction);
		}
	}, [direction]);

	useEffect(() => {
		const currentPlayer  = videoPlayer.current[activePlayer];
		if(currentPlayer && newPlayTime){
			currentPlayer.currentTime = newPlayTime;
		}
	}, [newPlayTime]);

	//#endregion

	//#region video event handling

	const videoEventHandler = (e: CustomEvent) => {
		switch(e.detail.type){
		case 'popup':
			setStopped(true);
			setVideoStopped && setVideoStopped(true);
			break;
		}
	};

	// checks wether the global variable videoStopped is being set to false.
	// videoStopped will never be set to false by this component
	useEffect(() => {

		const player = videoPlayer.current[activePlayer];
		if(!player) return;

		if(!videoStopped){
			setStopped(false);
		}

	},[videoStopped]);

	useEffect(() => {

		const player = videoPlayer.current[activePlayer];
		if(!player) return;

		if(stopped){
			player.pause();
			player.playbackRate = 0;
		}

		else{
			player.play();
		}
	},[stopped]);

	//#endregion


	const [tts, stts] = useState<boolean>(true);

	useEffect(() => {
		if(tts && value > 1 && showExtraTooltip){
			stts(false);
			// setShowExtra(false);
		}
	},[setShowExtra, showExtraTooltip, value, tts]);

	return(
		<>
			<Container>			
				{queuedPlayer && <LoaderClock/>}
				{
					getFileURL && data && data.video && data.video.videoForwards && data.video.videoBackwards && 
						<>
							<VideoPlayer // forwards player
								ref={(e) => videoPlayer.current.forwards = e} 
								loop={false} 
								muted 
								preload='true' 
								visible={activePlayer === 'forwards'} 
								onSeeked={(e) => onReadyToSwap()}
								onTimeUpdate={(e)=> updateEventChecking(videoPlayer.current.forwards?.currentTime)}
								onEnded={(e) => setShowEndscreen && setShowEndscreen(true)}
							>
								<source src={getFileURL(data.video.videoForwards.toString())}></source>
							</VideoPlayer>
							<VideoPlayer 
								ref={(e) => videoPlayer.current.backwards = e} 
								loop={false} 
								muted 
								preload='true'  
								visible={activePlayer === 'backwards'} 
								onSeeked={(e) => onReadyToSwap()}
							>
								<source src={getFileURL(data.video.videoBackwards.toString())}></source>
							</VideoPlayer>
						</>
						
				}
				{introductionState === 5 && <ScrollTooltip disable={value ? true : false}/>}	
				{showExtraTooltip && <ScrollTooltip disable={!tts}/>}	
			</Container>
			{introductionState === 3 && <ScrollTutorial/>}	

		</>					
	);
};

// styled components

const Container = styled.div`

	position: absolute;
	left:0;top:0;right:0;bottom:0;

	overflow: hidden;

	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;

    height:100%;
    width:100%;

    background: ${Theme.colors.primaryDark};

	z-index: -1;
`;

const VideoPlayer = styled.video<{visible: boolean}>`
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;

	visibility: ${p => p.visible ? 'visible' : 'hidden'} ;
`;

// types

type VideoPlayer = 'forwards' | 'backwards';

export default VideoBackground;
