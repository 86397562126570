
const Theme = {
	colors:{
		secondary: '#F3C000',
		primary: '#006E5F',
		primarylight: '#009286',
		primaryDark: '#004B41',
		neutral: 'white',
		neutralDark: '#4D4D4D',
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace : {
			xs: 200,
			sm: 200,
			lg: 200,
			xl: 150,
		}
	}
};

export default Theme;
