import { Directus, ID } from '@directus/sdk';
import { RateLimiter } from 'limiter';
import { Directer } from './directer/directer';

export const use_cms = false; //change to false for local data

export const directus_url = 'https://bsmp5xcg.directus.app/';
export const directus_auth_token = 'wat';

type DirectusType = 
| Directus<DirectusProps>
| Directer<DirectusProps>;

export let directus: DirectusType; 

if(use_cms){
	directus = new Directus<DirectusProps>(directus_url, {auth: {staticToken: directus_auth_token}});
} else {
	directus = new Directer<DirectusProps>();
}

export const limiter = new RateLimiter({ tokensPerInterval: 10, interval: 1000});

export type DirectusProps = {
    text_translations: TextTranslation,
	video: Video,
	video_events: VideoEvent,
	video_video_events: videoVideoEvent,
	directus_files: File,
	popups: Popup,
}

export type TextTranslation = {
	id: ID,
	languages_code: string,
	text: string,
	markdown: string,
	text_key: string,
}

export type Video = {
	id: ID,
	videoForwards: ID
	videoBackwards: ID
	video_events: ID[] // relation to junction collection
}

export type VideoEvent = {
	id: ID
	trigger_time: number
	event_key: string
	parameter: string
}

export type videoVideoEvent = {
	id: ID,
	video_events_id: ID
}

export type File = {
	id: ID
	modified_on: string
	filename_disk: string
	filename_download: string
}

export type Popup = {
	id: ID,
	label: string,
	type: 'video' | 'multiplechoice' | 'openendedquestion'
	title: string,
	description: string,
	title_english: string,
	description_english: string,
	options: string,
	options_english: string,
	video_url: string,
	description_markdown_english: string,
	description_markdown: string,
}

export type Password = {
	id: ID,
	code: string
}