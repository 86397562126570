import background from './background.mp3';
import background2 from './background2.mp3';
import background3 from './background3.mp3';

const soundlist = {
	'wereld1background' : background,
	'wereld2background' : background2,
	'wereld3background' : background3
};

export default soundlist;
