import { createGlobalStyle } from 'styled-components';
import { Univers57Condensed, Univers57CondensedOblique, Univers67CondensedBold } from '../assets/fonts';
import Theme from './theme';

// eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;
		font-family: 'Univers', sans-serif;
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
    	color: ${Theme.colors.neutral};
		user-select: none;
		font-weight: 100;

	}

	h1, h2,  h3 {
		font-size: 38px;
		margin: 0;
    	color: ${Theme.colors.neutral};
		font-weight: 300;

	}
	h2 {
		font-size: 30px;	
	}
	h1.english, h2.english, h3.english {
		font-size: 30px;
		font-weight: 300;
	}

	p.english{
		font-size: 1em;
		font-weight: 200;
	}

	input {
		font-weight: 300;
	}

	button{
		font-weight: 300;
	}

	p{
		font-size: 1.1em;
		margin: 0;
	}


	* {
		box-sizing: border-box;
	}

	#root {
		height: 100%;
	}

`;

export default GlobalStyle;
