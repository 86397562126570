import { directus } from '../../../../utilities/directus';

const passwordRequest = async() => {
	return {
		response : await directus.singleton('password').read().then((response) => {
			return response;
		}),
		collection: 'password'
	};
};

export default passwordRequest;