import Mutebutton from './components/Buttons/Mutebutton';
import Console from './components/Console';
import Input from './components/Input/Input';
import Endscreen from './components/screens/Endscreen/Endscreen';
import Popup from './components/screens/Popup/Popup';
import ScreenLock from './components/screens/ScreenLock';
import VideoBackground from './components/VideoBackground/VideoBackground';
import ContextProvider from './context/ContextProvider';
import CustomThemeProvider from './style/CustomThemeProvider';

function App() {
	return (
		<CustomThemeProvider>
			<ContextProvider>
				{ !process.env.NODE_ENV || process.env.NODE_ENV === 'development' &&
					<Console/>			
				}
				<Input/>
				<VideoBackground/>
				<Mutebutton/>
				<ScreenLock/>
				<Popup/>
				<Endscreen/>
			</ContextProvider>
		</CustomThemeProvider>
	);
}

export default App;
