import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import soundlist from '../../../assets/sounds';
import { useAudioContext } from '../../../context/contexts/audio/AudioContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import Theme from '../../../style/theme';
import PopupContent from './PopupContent';

const Popup = () => {

	const {setVideoStopped, setShowExtra} = useGlobalContext();
	const {playBackgroundSound} = useAudioContext();

	const [popupId, setpopupId] = useState<number>(0);
	const [showContent, setShowContent] = useState<boolean>(false);
	const [showExtraOnClose, setShowExtraOnClose] = useState<number>(0);

	useEffect(() => {		
		document.addEventListener('videoEvent', videoEventHandler as EventListener);
		return () => {
			document.removeEventListener('videoEvent', videoEventHandler as EventListener);
		};
	}, []);

	const videoEventHandler = (e: CustomEvent) => {
		switch(e.detail.type){
		case 'popup':
			setpopupId(e.detail.parameter);
			break;
		case 'setbackgroundsound':
			playBackgroundSound && playBackgroundSound(true, soundlist[e.detail.parameter]);
			break;
		}
	};

	useEffect(() => {
		if(popupId === 0){
			setVideoStopped && setVideoStopped(false);
			setShowContent(false);
			if(showExtraOnClose === 1){
				setShowExtraOnClose(2);
				setShowExtra && setShowExtra(true);
			}
		}
		if(showExtraOnClose === 0 && popupId !== 0) {
			setShowExtraOnClose(1);
		}
	},[popupId, setShowExtra]);

	return(
		<>
			{ popupId !== 0 && 
				<Container onAnimationEnd={() => setShowContent(true)}>
					{ showContent && 
						<PopupContent popupId={popupId} closePopup={() => setpopupId(0)}/>
					}
				</Container>
			} 
		</>
	);
};

// styled components

const popin = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const Container = styled.div`
	height: 100%;
	width: 100%;

	background-color: ${`${Theme.colors.primaryDark}70`}; // the 70 appended here is the opacity of the hex value

	animation: ${popin} 0.5s forwards;
`;

export default Popup;