import styled, { css } from 'styled-components';
import soundlist from '../../../assets/sounds';
import { useAudioContext } from '../../../context/contexts/audio/AudioContext';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import Theme from '../../../style/theme';
import BasicButton from '../../Buttons/BasicButton';
import { FadeIn, FadeOut } from './animations';
import Login from './Login/LoginScreen';

const IntroductionContent = () => {
	const {introductionState, setIntroductionState} = useDataContext();
	const {t} = useTextContext();
	const {playBackgroundSound} = useAudioContext();

	const startGame = () => {
		setIntroductionState && setIntroductionState(3);
		playBackgroundSound && playBackgroundSound(true, soundlist['wereld1background']);
	};

	return (
		<>
			{//login
				introductionState === 0 || introductionState === 1 ? 
					<Login/>
				//information
					: introductionState === 2 || introductionState === 3 ? 
						<InformationWrapper state={introductionState}>
							<h1 style={{maxWidth:'50%'}}>{t('loginpagina.introductie.title.standard')}</h1>
							<p style={{maxWidth:'45%', marginBlock: '15px'}}>
								{t('loginpagina.introductie.text.standard')}
							</p>
							<h1 className={'english'} style={{maxWidth:'40%', paddingTop: '30px'}}>{t('loginpagina.introductie.title.english')}</h1>
							<p className={'english'} style={{maxWidth:'40%', marginBlock: '15px'}}>
								{t('loginpagina.introductie.text.english')}
							</p>
							<div style={{marginTop: 'auto'}}>
								<BasicButton 
									style={{marginInline: 0}} 
									onClick={startGame}
								>
									{t('buttons.next')}
								</BasicButton>	
							</div>
						</InformationWrapper> 
						//no render
						: introductionState === 4 ?
							<InformationWrapper state={introductionState}>
								<h1 style={{maxWidth:'50%'}}>{t('loginpagina.introductie.title.standard')}</h1>
								<p style={{maxWidth:'45%', marginBlock: '15px'}}>
									{t('loginpagina.introductie.text.standard')}
								</p>
								<h1 className={'english'} style={{maxWidth:'40%', paddingTop: '30px'}}>{t('loginpagina.introductie.title.english')}</h1>
								<p className={'english'} style={{maxWidth:'40%', marginBlock: '15px'}}>
									{t('loginpagina.introductie.text.english')}
								</p>
								<div style={{marginTop: 'auto'}}>
									<BasicButton 
										style={{marginInline: 0}} 
										onClick={startGame}
									>
										{t('buttons.next')}
									</BasicButton>	
								</div>
							</InformationWrapper> 
							:	<></>}	
		</>);
};

//styled components
const InformationWrapper = styled.div<{state: number}>`

	display: flex;
	flex-direction: column;

	height: 100%;

	opacity: 0;
	animation-delay: 1s;

	${p => p.state === 2 ? css`
		animation: ${FadeIn} 1s forwards;

	` : css`
		animation: ${FadeOut} 1s forwards;
	`}

	overflow: auto;

	@media (max-width: ${Theme.responsive.media.lg}){
		> * {
			max-width: 100%!important;
		}
	}

	/* @media (min-width: ${Theme.responsive.media.lg}){
        padding-block: ${Theme.responsive.whitespace.lg - 50}px;
        margin-inline: ${Theme.responsive.whitespace.lg}px;
	} */


`;

export default IntroductionContent;