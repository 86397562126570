import styled, { keyframes } from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';

const LoaderClock = () => {
	const {getFileURL} = useDataContext();

	return(
		<Load img={getFileURL && getFileURL('9346c49e-16e5-41e2-a54c-d5ee6dcb24b9')}>

		</Load>
		// <Clock>
		// 	<Arm height={23} speed={0.5}/>
		// 	<Arm height={13} speed={2}/>
		// </Clock>
	);
};

// styled components

const rotate = keyframes`
	0% { -webkit-transform: rotate(0deg)}
	100% { -webkit-transform: rotate(-360deg)}
`;

const Load = styled.div<{img: string | undefined}>`
	position: relative;
	width: 70px;
	height: 70px;
	border-radius: 50px;

	z-index: 1;

	background-image: url(${p => p.img});
	background-size: cover;
	background-position: center;

	animation: ${rotate} 1s linear infinite;

`;

// const Clock = styled.div`
// 	position: relative;
// 	width: 70px;
// 	height: 70px;
// 	border-radius: 50px;
// 	border: solid 5px black;

// 	z-index: 1;

// 	background: white;
// `;

// const Arm = styled.div<{height: number, speed: number}>`
// 	position: absolute;
// 	left:50%;
// 	top:50%;

// 	animation: ${rotate} ${p => p.speed}s linear infinite;


// 	&:before{
// 		content: '';
// 		top: -2.5px;
// 		left: 50%;
// 		transform: translateX(-50%);
// 		background: black;
// 		position: absolute;
// 		width: 5px;
// 		height: ${p => p.height}px;
// 		border-radius: 5px;
// 	}
// `;

// const Minutes = styled.div`
	
// `;

export default LoaderClock;