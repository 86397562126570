import { handleEvent } from './scroll';

let yDown = null;

function getTouches(evt) {
	return evt.touches ||             // browser API
         evt.originalEvent.touches; // jQuery
}                                                     
//set starting point
export function handleTouchStart(evt) {
	const firstTouch = getTouches(evt)[0];                                      
	yDown = firstTouch.clientY;                                      
}                                                

export function handleTouchMove(evt) {
	
	if ( ! yDown ) {
		return;
	}
	//get current Y value
	const yUp = evt.touches[0].clientY;
	//calcualte difference between start and current
	const yDiff = yDown - yUp;

	if ( yDiff > 0 ) {
		//up
		handleEvent({deltaY: -1});
	} else { 
		//down
		handleEvent({deltaY: 1});
	}                                                                 
	// reset variable
	yDown = null;                                             
}