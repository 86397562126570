import { handleKeyPress } from './keys';
import { handleTouchMove, handleTouchStart } from './touch';

// values
const accelerationSpeed = 0.25; // in s
const maxAcceleration = 2; // in s
const decelerationSpeed = 0.03; // in s
const decelarationDelay = 100; // in ms
const decelerationRate = 100; // in ms

let timeOut;
let interval;

let acceleration = 0;
let deceleration = 0;

let forwards;

export const handleEvent = (e) => {
	setAcceleration(e);
	deceleration = 0;

	if(timeOut){
		clearTimeout(timeOut);
		clearInterval(interval);
	}
	//wait for deceleration after user stops scroling and start interval
	timeOut = setTimeout(() => {
		interval = setInterval(valueDeceleration, decelerationRate);
	}, decelarationDelay);
	onValueChange();
};

const setAcceleration = (e) => {
	
	// Clamp number between two values
	const min = 0;
	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
	acceleration = clamp(acceleration + accelerationSpeed, min, maxAcceleration);

	//forwards or backwards check
	if(e.deltaY < 0){
		forwards = true;
	} else if (e.deltaY > 0){
		forwards = false;
	}
};

const onValueChange = () => {
	//send detail package
	const event = new CustomEvent('scrollacceleration', { detail: {acceleration: acceleration, forwards: forwards, deceleration: deceleration}});
	document.dispatchEvent(event);
};

const valueDeceleration = () => {
	//increase deceleration value 
	deceleration = deceleration + decelerationSpeed;
	//take deceleration value of accelartion
	acceleration = acceleration - deceleration;
	onValueChange();
	if(acceleration === 0 || acceleration - deceleration < 0.01){
		clearInterval(interval);	
		acceleration = 0;
		deceleration = 0;
		onValueChange();
	}
};

//listen to wheel en touc events
const eventListener = () => {
	const c = document;
	if(c === null) return;

	c.addEventListener('wheel', handleEvent);
	//touch listeners
	c.addEventListener('touchstart', handleTouchStart);        
	c.addEventListener('touchmove', handleTouchMove);
	//key listeners
	c.addEventListener('keydown', handleKeyPress);

	return () => {
		c.removeEventListener('wheel', handleEvent);
		//touch listeners
		c.addEventListener('touchstart', handleTouchStart);        
		c.addEventListener('touchmove', handleTouchMove);
		//key listeners
		c.addEventListener('keydown', handleKeyPress);
	};	
};

export const forceScrollCall = (forwards: boolean) => {
	if(forwards)
		handleEvent({deltaY: -1});
	else
		handleEvent({deltaY: 1});
};

export default eventListener;