import { handleEvent } from './scroll';

export function handleKeyPress(e) {
	switch (e.keyCode) {
	case 37:
		//left
		break;
	case 38:
		//up
		handleEvent({deltaY: -1});
		break;
	case 39:
		//right
		break;
	case 40:
		//down
		handleEvent({deltaY: 1});
		break;
	}                                       
}