import { useState } from 'react';
import styled from 'styled-components';
import { useAudioContext } from '../../context/contexts/audio/AudioContext';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { ManyFileURL } from '../../context/contexts/data/types';

interface MuteProps {
    urls: ManyFileURL
}

const Mutebutton = () => {

	const {backgroundSource, voSource, audioSources, videoMuteState, setVideoMuteState, videoActive, setMuteState, muteState} = useAudioContext();
	const {getFileURL} = useDataContext();

	const mute = () => {
		setMuteState && setMuteState(true);
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = true;
			voSource.muted = true;
			audioSources.map((audioSource, index) => {
				audioSource.muted = true;
			});
		}
	};

	const soundon = () => {
		setMuteState && setMuteState(false);
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = false;
			voSource.muted = false;
			audioSources.map((audioSource, index) => {
				audioSource.muted = false;
			});
		}
	};

	const videomute = () => {
		setVideoMuteState && setVideoMuteState(true);
	};

	const videosoundon = () => {
		setVideoMuteState && setVideoMuteState(false);
	};

	return (
		<>
			{videoActive ? 
			//when video is active
				<>
					{videoMuteState ? 
						<MuteButton onClick={videosoundon} urls={{normal: getFileURL && getFileURL('58c61523-068e-4eab-9e03-a4cec137d05d'), hover: getFileURL && getFileURL('4d2111ed-1f2c-4feb-93bd-1c2f8d0852b6')}}/> 
						:
						<MuteButton onClick={videomute} urls={{normal: getFileURL && getFileURL('36548e7d-a23b-4086-bf7f-89d2504af2fc'), hover: getFileURL && getFileURL('599edc13-925d-4b39-9fc4-d7f6180fe53f')}}/>
					}
				</>
				: 
				//when video is not active
				<>
					{muteState ? 
						<MuteButton onClick={soundon} urls={{normal: getFileURL && getFileURL('58c61523-068e-4eab-9e03-a4cec137d05d'), hover: getFileURL && getFileURL('4d2111ed-1f2c-4feb-93bd-1c2f8d0852b6')}}/> 
						:
						<MuteButton onClick={mute} urls={{normal: getFileURL && getFileURL('36548e7d-a23b-4086-bf7f-89d2504af2fc'), hover: getFileURL && getFileURL('599edc13-925d-4b39-9fc4-d7f6180fe53f')}}/>
					}
				</>}
		</>
	);

};

//styled components

const MuteButton = styled.button<MuteProps>` 
	position: absolute;

	bottom: 0;
	right: 0;
	margin: 30px;
	height: 50px;
	width: 50px;

    background-image: url(${p => p.urls.normal});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	//opacity: 0.25;
	border: none;
	border-radius: 50%;

	z-index: 2;

	&:hover{
        cursor:pointer;
		background-image: url(${p => p.urls.hover});
	}
`;

export default Mutebutton;