import styled, { css} from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import Theme from '../../../style/theme';
import Container from '../../Containers/Container';
import { exit, ParralelloGramSlideLG, ParralelloGramSlideSM} from './animations';
import IntroductionContent from './IntroductionContent';

const Introduction = () => {
	const {introductionState} = useDataContext();
	const {getFileURL} = useDataContext();

	return (
		<OuterContainer background={getFileURL && getFileURL('369874a8-c91e-4197-84d7-5dfdba5e8462')} enabled={introductionState != 3 && introductionState != 5}>
			<Parralellogram loggedIn={introductionState ? introductionState === 2 || introductionState === 3 : false}/>
			<Container usedForIntroduction={true} focusBlock={introductionState ? introductionState >= 1 : false}>
				<IntroductionContent/>	
			</Container>
		</OuterContainer>
	);
};

// styled components

const Parralellogram = styled.div<{loggedIn: boolean}>`
	position: absolute;
    height: 100%;
    width: 200%;
    background-color: ${Theme.colors.primarylight};

	left: 50%;

    transform: skew(-28deg);

	@media (max-width: ${Theme.responsive.media.xl}){
		${ p => p.loggedIn && css`
		animation: ${ParralelloGramSlideSM} 1s forwards;
		`}
	}

	@media (min-width: ${Theme.responsive.media.xl}){
		${ p => p.loggedIn && css`
		animation: ${ParralelloGramSlideLG} 1s forwards;
		`}
	}

	z-index: -1;
`;

const OuterContainer = styled.div<{background: string | undefined, enabled: boolean}>`
	position: absolute;
	left:0;top:0;

	overflow: hidden;

    height:100vh;
    width:100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${p => p.background});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	background-color: ${Theme.colors.primaryDark};

	${ p => !p.enabled && css`
		animation: ${exit} 1s forwards;
	`}
	/* @media (max-width: ${Theme.responsive.media.lg}){
		:before{
			content: '';
			position: absolute;
			inset:0;
			background: ${Theme.colors.primarylight};
		}
	} */

	z-index: 9;
`;

export default Introduction;