import React, {useEffect, useState} from 'react';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
	
export const data = 0;
export const accelerationSpeed = 1;

const Input = () => {
	
	useEffect(() => {
		document.addEventListener('keyup', keyDown );
		
		return () => {
			document.removeEventListener('keyup', keyDown);
		};
	}, []);

	const {onToggleDisplayConsole} = useGlobalContext();

	const keyDown = (e: { keyCode: any; }) => {

		switch(e.keyCode){

		// Toggle Console Display 
		// F key
		case 70:
			// Toggle displayConsole when pressing F
			onToggleDisplayConsole && onToggleDisplayConsole();
			break;

		}	

	};

	return (
		<></>
	);
};

    
export default Input;