import { ID } from '@directus/sdk';
import { videoEvents, videoEventsJunction } from '../../context/contexts/data/types';
import { VideoEvent, videoVideoEvent } from '../../utilities/directus';

const events: VideoEvent[] = [];

const initializeEvents = (allEvents: videoEvents | undefined, videoEvents : (ID | undefined)[] | undefined, junction : (videoEventsJunction | undefined)) => {
	if(allEvents === undefined || videoEvents === undefined || junction === undefined ||  events.length > 0) return;

	videoEvents.map((value) => {
		if(value === undefined) return;
		events.push(allEvents[junction[value].video_events_id]);
	});
	// order events based on trigger time
	events.sort((a, b)=> {
		return a.trigger_time - b.trigger_time;
	});

};

// check the first event in the event arraay for trigger_time
// if trigger time is past the currentTime, dispatch the 'videoEvent' event.
const updateEventChecking = (currentTime: number | undefined) => {
	if(currentTime === undefined || events.length === 0) return;
	if(currentTime > events[0].trigger_time){
		const triggeredEvent = events.shift();
		const event = new CustomEvent('videoEvent', { 
			detail: {
				type: triggeredEvent?.event_key, 
				id: triggeredEvent?.id,
				parameter: triggeredEvent?.parameter
			}
		});
		document.dispatchEvent(event);
	}
};


export {updateEventChecking, initializeEvents};