import { PopupContentProps } from '../PopupContent';
import Container from '../../../Containers/Container';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useTextContext } from '../../../../context/contexts/text/TextContext';
import BasicButton from '../../../Buttons/BasicButton';
import NotchedDiv from '../../../Containers/NotchedDiv';
import Theme from '../../../../style/theme';

const OpenEndedQuestion = ({popupData, onClose} : PopupContentProps) => {

	const {t} = useTextContext();

	const [inputValue, setInputValue] = useState<string>('');

	return(
		<Container fadeIn={true} focusBlock={true}>
			<ContentContainer>
				<div>
					<h1 style={{textTransform:'uppercase', marginBottom: '20px'}}>{popupData.title}</h1>
					<h1 className='english' style={{textTransform:'uppercase'}}>{popupData.title_english}</h1>
				</div>
				<TextArea>
					<TextAreaBorder/>
					<TextAreaBackdrop/>
					<TextAreaInput
						onChange={e => setInputValue(e.target.value)} 
						value={inputValue}
						placeholder={t('popups.openendedquestion.textarea.placeholder')}
					/>
				</TextArea>
				<div style={{display: 'flex'}}><BasicButton onClick={() => onClose(inputValue)}>{t('questionbutton.send')}</BasicButton></div>
			</ContentContainer>
		</Container>
	);
};

// styled components

const ContentContainer = styled.div`
	overflow-y: auto;
	margin-inline: -50px;
	padding-inline: 50px;
`;

const TextArea = styled.div`
	position: relative;
	min-height: 100px;
	flex-grow: 1;
	background: 0;
	margin-block: 20px;
`;

const TextAreaBackdrop = styled(NotchedDiv)`
	position: absolute;
	left:0;top:0;
	height: 100%;
	width: 100%;

	background-color: ${Theme.colors.neutral};
`;

const borderSize = -5;
const TextAreaBorder = styled(NotchedDiv)`
	position: absolute;
	left:${borderSize}px; top: ${borderSize}px; right: ${borderSize}px; bottom: ${borderSize}px;
	background-color: ${Theme.colors.secondary};
`;

const TextAreaInput = styled.textarea`
	position: absolute;
	left:0;top:0;
	height: 100%;
	width: 100%;

	font-family: 'Roboto', sans-serif;
	outline: none;
	border: none;
	font-size: 1.2rem;
	padding: 20px;
	background: 0;
	resize: none;
`;

export default OpenEndedQuestion;