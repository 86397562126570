import { ReactNode } from 'react';
import styled from 'styled-components';
import Theme from '../../style/theme';
import Button from './Button';

 
const BasicButton = (props: {disabled?, style?, children: ReactNode, className?: string, onClick?: () => void}) => {
	return (  
		<StyledButton style={props.style} disabled={props.disabled} onClick={() => props.onClick && props.onClick()}className={props.className}>
			{props.children}
		</StyledButton>
	);
};

// styled components

const StyledButton = styled(Button)`
    
    background-color: ${Theme.colors.secondary};

    color: ${Theme.colors.primaryDark};
    font-weight: bold;
	font-size: 1.3rem;
	
	padding: 0.5rem 2rem;
	margin-inline: auto;

	&:hover { 
		filter:brightness(1.05);
	}
`;


 
export default BasicButton;
