import { directus } from '../../../../utilities/directus';

const eventsRequest = async() => {
	const response = {};

	await directus.items('video_events').readByQuery({limit: -1}).then((data) => {
		if(!data.data) return;

		data.data.map((value) => {
			if(!value || !value.id) return;
			response[value.id] = value;
		});
	});

	return {
		response : response,
		collection: 'video_events'
	};
};

export default eventsRequest;