import styled, { keyframes } from 'styled-components';
import Theme from '../../../style/theme';


// variables 

const size = {
	sm: 180,
	lg: 180,
};

// keyframes

const yOffset = '-150px';

const moveIn = {

	sm: keyframes`
        0%{
            transform: translate(-${Theme.responsive.whitespace.sm * 2}px, ${yOffset})
        }
        50%{
            transform: translate(-${Theme.responsive.whitespace.sm * 2}px, ${yOffset})
        }
        100%{
            transform: translate(-${Theme.responsive.whitespace.sm}px, ${yOffset})
        }
    `,

	lg: keyframes`

    0%{
        transform: translate(-${Theme.responsive.whitespace.lg * 2}px, ${yOffset})
    }
    50%{
        transform: translate(-${Theme.responsive.whitespace.lg * 2}px, ${yOffset})
    }
    100%{
        transform: translate(-${Theme.responsive.whitespace.lg}px, ${yOffset})
    }
    `

};

// styled components

const FocusBlock = styled.div`
    height: 0;
    width: 0;

    position: absolute;
    left:0;
    top:0;

    @media (max-width: ${Theme.responsive.media.lg}){
        border-top: ${size.sm}px solid transparent;
        border-left: ${size.sm}px solid ${Theme.colors.secondary};
        border-bottom: ${size.sm}px solid transparent;

        transform: translate(-${Theme.responsive.whitespace.sm}px, ${yOffset});
        animation: ${moveIn.sm} 2s forwards;

	}

	@media (min-width: ${Theme.responsive.media.lg}){
        border-top: ${size.lg}px solid transparent;
        border-left: ${size.lg}px solid ${Theme.colors.secondary};
        border-bottom: ${size.lg}px solid transparent;

        transform: translate(-${Theme.responsive.whitespace.lg}px, ${yOffset});
        animation: ${moveIn.lg} 2s forwards;
	}

`; 


export default FocusBlock;