import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const NotchedDiv = (props: NotchedDiv) => {

	const container = useRef<HTMLDivElement>(null);  // reference to option

	useEffect(() => {
		window.addEventListener('resize', updateNotchedSize);

		return () => {
			window.removeEventListener('resize', updateNotchedSize);
		};
	},[]);

	useEffect(() => {
		if(!container.current) return;
		updateNotchedSize();
	}, [container]);

	const [notchedSideSizes, setNotchedSideSizes] = useState<NotchedSideSizes>({x: 0, y: 0});

	const updateNotchedSize = () => {
		if(!container.current) return;
		const y = container.current.clientHeight * (0.6875); 
		const x = container.current.clientWidth - (container.current.clientHeight * (0.3125));
		setNotchedSideSizes({x: x, y: y});
	};

	return <_NotchedDiv 
		className={props.className} 
		onClick={props.onClick} 
		sizes={notchedSideSizes}
		ref={container}
	>
		{props.children}
	</_NotchedDiv>;
};

const _NotchedDiv = styled('div')<{sizes: NotchedSideSizes}>`
	clip-path: polygon(100% 0, 100% ${p => p.sizes.y}px, ${p => p.sizes.x}px 100%, 0 100%, 0 0);
`;

type NotchedSideSizes = {x: number, y: number}
type NotchedDiv = (DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>);

export default NotchedDiv;