import styled from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';
import BasicButton from '../Buttons/BasicButton';

const ScrollTutorial = () => {

	const { getFileURL, setIntroductionState} = useDataContext();
	const {t} = useTextContext();

	return(
		<Container>
			<Inner>
				<Row>
					<Column>
						<StyledH2>
							{t('scrolltutorial.title')}
						</StyledH2>
						<p>
							{t('scrolltutorial.text')}
						</p>
					</Column>
					<Column style={{fontStyle: 'italic'}}>
						<StyledH2>
							{t('scrolltutorial.title.english')}
						</StyledH2>
						<p>
							{t('scrolltutorial.text.english')}
						</p>
					</Column>
				</Row>
				<Row>
					<Column>
						<Icon url={getFileURL && getFileURL('61573c9f-b352-4f23-b16f-702d0ad4b15a')}/>
						<IconText>
							<p>{t('scrolltutorial.method1.text')}</p>
							<br/>
							<i>{t('scrolltutorial.method1.text.english')}</i>
						</IconText>
					</Column>
					<Column>
						<Icon url={getFileURL && getFileURL('c0233d91-75d0-4a5d-931e-a689d2691f2e')}/>
						<IconText>
							<p>{t('scrolltutorial.method2.text')}</p>
							<br/>
							<i>{t('scrolltutorial.method2.text.english')}</i>
						</IconText>
					</Column>
					<Column>
						<Icon url={getFileURL && getFileURL('675de0ce-b1f6-4e47-9e3b-018863e9ba38')}/>
						<IconText>
							<p>{t('scrolltutorial.method3.text')}</p>
							<br/>
							<i>{t('scrolltutorial.method3.text.english')}</i>
						</IconText>
					</Column>
				</Row>
			</Inner>
			<BasicButton onClick={() => {setIntroductionState && setIntroductionState(5);}}>Continue</BasicButton>
		</Container>
	);
};

// styled components

const Container = styled.div`
	height: 100%; width: 100%;
	padding: 50px;

	display: flex;
	flex-direction: column;
`;

const Inner = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
	margin-bottom: 25px;
    gap: 25px;    

    overflow-y: auto;


	@media (max-width: ${Theme.responsive.media.lg}){
        padding: 25px;
	}

	@media (min-width: ${Theme.responsive.media.lg}){
        padding: 50px;
	}


`;

const StyledH2 = styled.h2`
    color: ${Theme.colors.primary};
    margin-bottom: 10px;
	margin-right: auto;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
`;

const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: ${Theme.colors.neutralDark};
	align-items: center;
	gap: 10px;
`;

const Icon = styled.div<{url: string | undefined}>`

	
	width: 100%;
	max-width: 200px;
	margin-inline: auto;
	aspect-ratio:  1/1;
	padding: 25px;

    background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);
`;

const IconText = styled.div`
	width: 80%;
`;
export default ScrollTutorial;