import { keyframes } from 'styled-components';

const exit = keyframes`
from{
    opacity: 1;
}
to{
    opacity: 0;
    display: none;
    pointer-events: none;
}
`;

export const ParralelloGramSlideLG = keyframes`
0%{
	left: 50%;
}
100%{
    left: -150%;
}
`;

export const ParralelloGramSlideSM = keyframes`
0%{
	left: 50%;
}
100%{
    left: -50%;
}
`;

const FadeIn = keyframes`
0%{
    opacity: 0;
}
100%{
    opacity: 1;
}
`;

const FadeOut = keyframes`
0%{
    opacity: 1;
}
100%{
    opacity: 0;
}
`;


export {exit, FadeOut, FadeIn};