import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import ReactGA from 'react-ga4';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	ReactGA.initialize('G-GN6RFLV59H');
	ReactGA.send('pageview');
} else {
	ReactGA.initialize('G-GN6RFLV59H');
	ReactGA.send('pageview');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
