import { directus } from '../../../../utilities/directus';

const videoRequest = async() => {
	return {
		response : await directus.singleton('video').read().then((response) => {
			return response;
		}),
		collection: 'video'
	};
};

export default videoRequest;