import { createContext, useContext, useState } from 'react';
import { ContextProps, GlobalContextProps } from './types';

const GlobalContext = createContext<Partial<GlobalContextProps>>({});

const GlobalContextProvider = (props: ContextProps) => {

	//#region functions
	const onToggleDisplayConsole = () => {
		setDisplayConsole(e => !e);
	};

	//#endregion

	//#region usestates
	const [ displayConsole, setDisplayConsole] = useState<boolean>(false);

	const [currentPlayTime, setCurrentPlayTime] = useState<number>();

	const [newPlayTime, setNewPlayTime] = useState<number>();

	const [videoStopped, setVideoStopped] = useState<boolean>(false);

	const [showEndscreen, setShowEndscreen] = useState<boolean>(false);

	const [showExtraTooltip, setShowExtra] = useState<boolean>(false);

	//#endregion

	//#region useEffects

	//#endregion
	
	const passedFunctions = {
		setDisplayConsole,
		onToggleDisplayConsole,
		setCurrentPlayTime,
		setVideoStopped,
		setShowEndscreen,
		setNewPlayTime,
		setShowExtra,
	};

	const passedValues = {
		displayConsole,
		currentPlayTime,
		videoStopped,

		showEndscreen,
		newPlayTime,
		showExtraTooltip
	};		

	

	//#endregion

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
