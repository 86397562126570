import { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../../../components/screens/Loading/Loading';
import Introduction from '../../../components/screens/Introduction/Introduction';
import { directus_auth_token, directus_url, limiter, use_cms } from '../../../utilities/directus';
import requests from './requests';
import { ContextProps, DataContextProps, RequestResponse, ResponseData } from './types';

const DataContext = createContext<Partial<DataContextProps>>({});

const DataContextProvider = (props: ContextProps) => {
	
	// state indicates the loading state of data
	// 0 === not started loading
	// 1 === started loading and request are being handles
	// 2 === finished loading and data has been acquired succesfully
	// 3 === finished loading, but part/all of the data has not been acquired succesfully.
	// 4 === loading timed out
	const [dataState, setDataState] = useState<number>(0);

	
	//0 = login screen
	//1 = fadeout login
	//2 = information screen
	//3 = render nothing
	const [introductionState, setIntroductionState] = useState<number>(0);

	useEffect(() => {
		dataState === 0 && initialize();
	},[dataState]);

	const initialize = () => {
		setDataState(1);
		getData();
	};

	const getData = async() => {
		const rqs: Promise<RequestResponse>[] = [];

		for(let i = 0; i < requests.length; i++){
			await limiter.removeTokens(1);
			rqs.push(requests[i]());
		}
		
		await Promise.all(rqs).then((responses) => {
			const responsesObject = {};
			responses.map((value) => {
				responsesObject[value.collection] = value.response;
			});
			setData(responsesObject);

		}).catch(() => {

			setDataState(3);

		});
	};

	const [data, setData] = useState<undefined | ResponseData>();

	useEffect(() => {
		if(data){
			setDataState(2);
		}
	},[data]);


	// return an file url based on given string. 
	// The url is built up from different variables which allow for cache breaking the file.
	const getFileURL = (id: string): string  => {

		if(!data || !data.files ) return 'file data not found';
		
		const file = data.files[id];

		if(file !== undefined){
			if(use_cms){
				return `${directus_url}assets/${file.filename_disk}&${file.modified_on}?access_token=${directus_auth_token}`;
			} else {
				return file ? require(`../../../assets/files/${file.filename_disk}`) : 'file not found';
			}
		}

		return '';
	};

	const passedValues = {
		dataState,
		data,
		introductionState
	};

	const passedFunctions = {
		getFileURL,
		setIntroductionState
	};

	return (
		<>	
			<DataContext.Provider value={{...passedValues, ...passedFunctions}}>
				<Loading dataState={dataState}/>
				<Introduction/>
				{ dataState === 2 &&
					<>{props.children}</>
				}
			</DataContext.Provider>
		</>
	);

};

const useDataContext = () => useContext(DataContext);


export {DataContext, DataContextProvider, useDataContext};
