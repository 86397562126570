import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';

const ScrollTooltip = (props: {disable: boolean}) => {

	const {t} = useTextContext();
	const {getFileURL} = useDataContext();

	return (
		<Container fadeOut={props.disable}>
			<ScrollBox>
				<div style={{aspectRatio: '1/1'}}>
					<Mouse url={getFileURL && getFileURL('575f0056-973a-4ff0-8b53-4df9aee43d43')}>
						<Arrow url={getFileURL && getFileURL('d9e053e6-d918-4ade-8c54-0f5490896eeb')}/>
					</Mouse> 
				</div>
				<div>
					<Title style={{color: 'black'}}>{t('scrolltooltip.title')}</Title>
					<p>{t('scrolltooltip.description')}</p>
				</div>
			</ScrollBox>
			<ScrollBox >
				<div style={{aspectRatio: '1/1'}}>
					<Mouse url={getFileURL && getFileURL('575f0056-973a-4ff0-8b53-4df9aee43d43')}>
						<Arrow url={getFileURL && getFileURL('d9e053e6-d918-4ade-8c54-0f5490896eeb')}/>
					</Mouse> 
				</div>
				<div>
					<Title style={{color: 'black'}}>{t('scrolltooltip.title.english')}</Title>
					<p>{t('scrolltooltip.description.english')}</p>
				</div>
			</ScrollBox>
		</Container>
	);
};

//styled components

const fadeOut = keyframes`
	0%{
		bottom: 0;
	}

	10%{
		bottom: 10%;
	}

	100%{
		bottom: -100%;
	}
`;

const Container = styled.div<{fadeOut: boolean}>`
    position: absolute;
	bottom:0%;
	display: flex;
    flex-direction: row;

	${p => p.fadeOut && css`
		animation: ${fadeOut} 3s forwards;
	`}
`;

const ScrollBox = styled.div`
    background-color: ${Theme.colors.neutral};

	max-width: 400px;

	margin: 25px;
	padding: 20px;
	gap: 20px;

    display: flex;
    flex-direction: row;
	align-items: stretch;

	color: ${Theme.colors.neutralDark};

	text-align: left;

	pointer-events: none;

	& > div {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

`;

// const Row = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     text-align: left;
// `;
    
const Title = styled.h1`
	color: ${Theme.colors.primary}!important;
	font-size: 1.4rem;
	margin-bottom: 5px;
`;

const arrowBounce = keyframes`
0%{
    top:0px;
}
50%{
	top:-7px;
}
100%{
    top:0px;
}
`;

const Mouse = styled.div<{url: string | undefined}>`

	position: relative;

    background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);

	height: 100px;
	width: 100px;
	margin: auto;
`;

const Arrow = styled.div<{url: string | undefined}>`

	position: absolute;
	left:0;
	top:10;

	height: 100%;
	width: 100%;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-color: rgba(0,0,0,0);


	animation: ${arrowBounce} 1s ease-in-out infinite;
`;

export default ScrollTooltip;
