import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTextContext } from '../../context/contexts/text/TextContext';
import Theme from '../../style/theme';

interface Size {
	width: number;
	height: number;
}

const ScreenLock = () => {

	const {t} = useTextContext();

	const [size, setSize] = useState<Size>({width: window.innerWidth, height: window.innerHeight});

	const render = size.width < 600 && size.width < size.height;

	useEffect(() => {
		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	},[]);

	const resizeHandler = () => {
		setSize({height: window.innerHeight, width: window.innerWidth});
	};

	return (
		<>
			{ render &&
				<Container>
					<h1>{t('screens.screenlock.message')}</h1>
				</Container>
			}
		</>
	);
};

//styled components

const Container = styled.div`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: 9001;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: ${Theme.colors.primaryDark};
	text-align: center;
`;

export default ScreenLock;