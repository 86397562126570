import { ReactNode, useEffect, useState } from 'react';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { Popup } from '../../../utilities/directus';
import ReactGA from 'react-ga4';

import OpenEndedQuestion from './components/OpenEndedQuestion';
import MultipleChoice from './components/MultipleChoice';
import Video from './components/Video';
import { useAudioContext } from '../../../context/contexts/audio/AudioContext';

const popupComponents = {
	'openendedquestion': OpenEndedQuestion,
	'multiplechoice': MultipleChoice,
	'video': Video,
};

const PopupContent = ({popupId, closePopup} : {popupId: number, closePopup: () => void}) => {


	// gather the right popup data

	const {data} = useDataContext();
	const {setVideoActive} = useAudioContext();
	
	const [popupData, setPopupData] = useState<Popup | undefined>();

	useEffect(() => {
		if(!data || !data.popups) return;
		if(data.popups[popupId].type === 'video'){			
			setVideoActive && setVideoActive(true);
		}
		setPopupData(data.popups[popupId]);
	},[]);
	

	
	const onClosePopup = (ans: string) => {
		if(!popupData) return;

		ReactGA.event({
			action: 'popup-answer',
			category: `popup-${popupData.type}-${popupId}`,
			label: ans
		});

		closePopup();
	};

	const PopupContentComponent = data?.popups && popupComponents[data.popups[popupId].type];
	
	return(
		<>
			{popupData  && PopupContentComponent && 
				<PopupContentComponent popupData={popupData} onClose={(a) => onClosePopup(a)}/>
			}
			{/* based on popup data type, show a different component and pass the data on */}
		</>
	);
};

// styled components

// types

export type PopupContentProps = {
	popupData: Popup;
	onClose: (val: string) => void;
}	

export default PopupContent;