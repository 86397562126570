import React, {useState, useRef, ReactComponentElement, ReactNode, useEffect} from 'react';

import styled from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import GeneralCT from './components/GeneralCT';
import ScrollingCT from './components/ScrollingCT';


const tabs: any  = {
	'scrolling': ScrollingCT,
	'general': GeneralCT,
};

const Console = () => {
	const {displayConsole} = useGlobalContext();
	
	const [activeInterfaceItem, setActiveInterfaceItem] = useState<string>('scene');
	const [tabLabels, setTabLabels] = useState<string[] | undefined>();

	useEffect(() => {
		if(!tabLabels){
			const labels: string[] = [];
			for (const [key] of Object.entries(tabs)) {
				labels.push(key);
			}
			setTabLabels(labels);
		}
	}, []);

	const setActiveTab = (key: string) => {
		setActiveInterfaceItem(key);
	};

	//const [ActiveTab, setActiveTab] = useState<ReactNode | undefined>();
	const ActiveTab = tabs[activeInterfaceItem];
	// Render Function
	return (
		<> 
			{displayConsole && 
				<ConsoleContainer>
					<ConsoleWrap>
						{/* <ConsoleTitle>{t('console.title')}</ConsoleTitle> */}
						<TabContainer>
							{ tabs && Object.keys(tabs).map((key, index) => (
								<button style={activeInterfaceItem === key ? {outline: 'solid 2px cyan'} : {}} key={`console-tabs-${index}-${key}`} onClick={() => setActiveTab(key)}>{key}</button>
							))}
						</TabContainer>				
						{ ActiveTab &&
							<ActiveTab/>
						}		
						<Version>{process.env.REACT_APP_VERSION}</Version>
					</ConsoleWrap>
				</ConsoleContainer>
			}
		</>
	);
	
};


//#region styled components

const ConsoleContainer = styled.div`
    z-index: 10;
    position: absolute;
    right:0;
    top:0;
	height: auto;
    display: flex;
    justify-content: center;
	width:100vw;
	text-align: left;
`;

const ConsoleWrap = styled.div`
    z-index: 5;
    width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    color: white;
    overflow: auto;
    text-align: left;
	padding: 20px;
`;

const TabContainer = styled.div`

	& > button {
		margin-right: 10px;
	}

`;

const Version = styled.div`
	position:absolute;
	right:0;
	top:0;

	color: white;
	margin: 1px;
`;

//#endregion

export default Console;

